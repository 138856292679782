<template>
  <div>
    <v-dialog
      class="overflow-visible"
      no-click-animation
      max-width="1000"
      v-model="show"
      persistent
      scrollable
    >
      <close-button @click="closeDialog" overflow />
      <v-card>
        <v-card-title :class="$classes.cardTitle">
          <span class="font-weight-bold headline">Shopping List</span>
        </v-card-title>
        <v-card-text class="pa-0 pt-lg-5 pb-lg-7">
          <preloader text="Loading" v-if="loading" />
          <div v-else :style="mdUp ? 'max-width: 1000px' : 'max-width: 330px'">
            <v-card flat class="mb-3" v-for="(data, i) in tableData" :key="i">
              <v-card-text class="pa-3">
                <v-simple-table class="grey lighten-4">
                  <template v-slot:default>
                    <thead>
                      <tr>
                        <th
                          v-for="(header, i) in data.headers"
                          width="29%"
                          :key="i"
                        >
                          {{ header }}
                        </th>
                        <th width="10%" class="text-center">
                          Recommended Product
                        </th>
                        <th width="5%">Edit</th>
                        <th widht="5%">Remove</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="(content, i) in data.content" :key="i">
                        <td v-for="(ing, i) in content.ing" :key="i">
                          {{ ing }}
                        </td>
                        <td>
                          <div v-if="getLink(content.id) == '-'">-</div>
                          <v-btn
                            v-else
                            x-small
                            depressed
                            color="success"
                            @click="buyProduct(getLink(content.id))"
                          >
                            Buy Now
                            <!-- <v-icon x-small>mdi-pencil</v-icon> -->
                          </v-btn>
                        </td>
                        <td>
                          <v-btn
                            x-small
                            color="info"
                            @click="showSelectedIng(data.id, content.ing, i)"
                          >
                            <v-icon x-small>mdi-pencil</v-icon>
                          </v-btn>
                        </td>
                        <td>
                          <v-btn
                            x-small
                            color="error"
                            @click="removeSelected(data.id, i)"
                          >
                            <v-icon x-small>mdi-trash-can-outline</v-icon>
                          </v-btn>
                        </td>
                      </tr>
                    </tbody>
                  </template>
                </v-simple-table>
              </v-card-text>
            </v-card>
          </div>
        </v-card-text>
        <v-card-actions class="py-5">
          <v-row>
            <v-col cols="12">
              <v-btn
                @click="
                  $store.getters['subscriptions/subscribed']
                    ? dlShoppingList()
                    : $root.$emit('showSubscribeDialog')
                "
                class="mr-2 primary--text float-right"
                v-if="
                  $route.name !== 'Ebook' && $route.name !== 'MealplanEbook' && $route.name !== 'PromoEbookPage'
                "
                :loading="downloadingShoppingList"
                color="white"
                small
              >
                <v-icon left>mdi-download</v-icon>
                Download List
              </v-btn>
              <v-btn
                @click="dlShoppingList()"
                class="mr-2 white--text float-right"
                v-else
                :loading="downloadingShoppingList"
                :color="user?.brandColor"
                small
              >
                <v-icon left>mdi-download</v-icon>
                Download List
              </v-btn>
            </v-col>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- EDIT DIALOG -->
    <v-dialog
      class="overflow-visible"
      no-click-animation
      max-width="300"
      v-model="showSelectedDialog"
    >
      <close-button @click="showSelectedDialog = false" overflow />
      <v-card class="elevation-16 mx-auto" width="300">
        <v-card-title>
          <span class="font-weight-bold headline">Edit</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12">
                <v-text-field v-model="selectedRow.food" label="Food" required>
                </v-text-field>
              </v-col>
              <v-col cols="12">
                <v-text-field
                  v-model="selectedRow.quantity"
                  label="Quantity"
                  required
                >
                </v-text-field>
              </v-col>
              <v-col cols="12">
                <v-text-field
                  v-model="selectedRow.measure"
                  label="Measure"
                  required
                >
                </v-text-field>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-row>
            <v-col cols="12">
              <v-btn
                small
                color="info"
                class="float-right"
                @click="updateSelected(selectedRow.id)"
              >
                Update
              </v-btn>
            </v-col>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- END EDIT DIALOG -->

    <!-- 
            /**
            * TODO: GET OTHER RECIPES
            */
         -->

    <!-- VIEW RECIPE DIALOG -->
    <!-- <v-dialog
            class="overflow-visible"
            no-click-animation
            max-width="400"
            v-model="recipesDialog"
            scrollable
        >
            <close-button @click="recipesDialog = false" overflow/>
            <v-card class="elevation-16 mx-auto" width="400">
                <v-card-title :class="$classes.cardTitle">
                    <span>Other recipes for this ingredient:</span>
                </v-card-title>
                <v-card-text>
                    <v-container>
                        <v-row class="py-2">
                            <v-col cols="6">
                                <v-card
                                    max-width="400"
                                >
                                <template slot="progress">
                                    <v-progress-linear
                                        color="primary"
                                        height="5"
                                        indeterminate
                                    ></v-progress-linear>
                                </template>

                                <v-img
                                    height="100"
                                    src="https://firebasestorage.googleapis.com/v0/b/cq-nutrition.appspot.com/o/recipes%2Fmedium_1653977893632_Cream%20Cheese%20%26%20Tuna%20Sandwich.png?alt=media&token=dd1ad80f-ac83-4a15-a369-d70130a504f9">

                                </v-img>

                                <v-card-title>Apricot</v-card-title>

                                </v-card>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-card-text>
            </v-card>
        </v-dialog> -->
  </div>
</template>

<script>
import { uniq, uniqBy, orderBy } from 'lodash';
import { mapState } from 'vuex';

// MIXINS
import ShoppingList from '../mixins/download-shopping-list';

export default {
  name: 'ShoppingListDialog',

  /*------------------------------------------------------------------------------
   * MIXINS
   *----------------------------------------------------------------------------*/
  mixins: [ShoppingList],
  data() {
    return {
      show: false,
      tableData: [],
      showSelectedDialog: false,
      downloadingShoppingList: false,
      loading: true,
      selectedRow: {
        food: '',
        quantity: '',
        measure: '',
        id: '',
        arrPos: '',
      },
    };
  },
  props: {
    meals: Array,
    recipes: Array,
    user: Object,
  },
  computed: {
    ...mapState({
      ings: (state) => state.ingredients.ingredients,
      portions: (state) => state.ingredients.portions,
    }),
    mdUp: function () {
      return this.$vuetify.breakpoint.mdAndUp;
    },
  },
  methods: {
    showDialog() {
      this.show = true;
      this.tableData = [];
      this.loadIngs();
    },
    closeDialog() {
      this.show = false;

      this.tableData = [];
    },
    showSelectedIng(id, content, arrPos) {
      this.showSelectedDialog = true;
      const headers = ['food', 'quantity', 'measure'];

      this.selectedRow.id = id;
      this.selectedRow.arrPos = arrPos;

      content.forEach((cont, i) => (this.selectedRow[headers[i]] = cont));
    },
    updateSelected(id) {
      const selected = this.tableData.find((data) => data.id === id);

      selected.content[this.selectedRow.arrPos].ing[0] = this.selectedRow.food;
      selected.content[this.selectedRow.arrPos].ing[1] =
        this.selectedRow.quantity;
      selected.content[this.selectedRow.arrPos].ing[2] =
        this.selectedRow.measure;

      this.showSelectedDialog = false;
    },
    removeSelected(id, arrPos) {
      const selected = this.tableData.find((data) => data.id === id);

      selected.content.splice(arrPos, 1);

      if (!selected.content.length) {
        this.tableData = this.tableData.filter((data) => data.id !== id);
      }
    },
    displayIngs() {
      return new Promise((resolve) => {
        setTimeout(() => {
          let header = ['Food', 'Quantity', 'Measure'];
          let ingredientsCollection = [];
          let categories = [];
          let meals;

          if (
            this.$route.name == 'Ebook' ||
            this.$route.name == 'MealplanEbook'
          )
            meals = this.meals;
          else
            meals = this.meals.filter(
              (m) => m.recipe !== 'no meal' && m.mealPrep !== true
            );

          meals.forEach((meal) => {
            let recipe = this.recipes.find((r) => r.id == meal.recipe);

            if (recipe) {
              if (recipe.ingredients && recipe.ingredients.length) {
                recipe.ingredients.forEach(async (ing) => {
                  let ingredient = this.ings.find((i) => i.name == ing.food);
                  if (ingredient && !ingredient.hideShoppingList) {
                    ingredientsCollection.push({
                      food: ing.food,
                      recipe: recipe.id,
                      portion: ing.portion,
                      category: ingredient.category || null,
                      id: ingredient.id,
                      defaultMeasurement: ingredient?.defaultMeasurement,
                      defaultWeight: ingredient?.defaultWeight,
                      mealServes: meal.serves,
                      quantity: recipe.lock_serve
                        ? ing.quantity
                        : parseFloat(
                            ing.quantity *
                              (
                                (meal.serves || recipe.numberOfServes) /
                                recipe.numberOfServes
                              ).toFixed(2)
                          ),
                    });

                    let category = ingredient.category;
                    if (category) categories.push(category);
                  }
                });
              }
            }
          });

          categories = uniq(categories);

          if (categories.length) {
            categories.forEach((category, i) => {
              let header = [
                this.$store.getters['foods/categoryData'](category).name,
                'Quantity',
                'Measure',
              ];

              let data = [];

              var categorizedIngredients = ingredientsCollection.filter((i) => {
                return i.category == category && i.mealServes;
              });

              let uniqCollection = uniqBy(
                categorizedIngredients,
                function (meal) {
                  return `${meal.food}`;
                }
              );

              uniqCollection = orderBy(uniqCollection, 'food', 'asc');

              uniqCollection = uniqCollection.map((ing) => {
                let sum = parseFloat(ing.quantity);
                let sumWithDefaultWeight;
                let portion = ing.portion;

                let ingredients = categorizedIngredients.filter(
                  (i) => i.food == ing.food
                );

                // if (ingredients[0].food == 'Skim milk, (0.15% fat)') {
                //   console.log(ingredients)
                // }
                // if (ingredients[0].food == 'Cauliflower rice, uncooked') {
                //   console.log(ingredients)
                // }
                if (ingredients[0].food == 'Carrot, raw') {
                  console.log(ingredients)
                }


                if (ingredients.length > 1) {
                  sum = 0;
                  sumWithDefaultWeight = 0;
                  portion = 'gram';

                  ingredients.forEach((i) => {
                    if (i.portion == 'gram') {
                      if (!i?.defaultWeight) {
                        sum += parseFloat(i.quantity);
                      }

                      if (i?.defaultWeight) {
                        sumWithDefaultWeight += parseFloat(i.quantity);
                      }
                    } else {
                      if (!i?.defaultWeight) {
                        let portionData = this.portions.find(
                          (p) => p.food == i.food && p.name == i.portion
                        );

                        if (portionData) {
                          sum +=
                            parseFloat(portionData?.weight) *
                            parseFloat(i.quantity);
                        } else {
                          sum += parseFloat(i.quantity);
                        }
                      }

                      if (i?.defaultWeight) {
                        let portionData = this.portions.find(
                          (p) => p.food == i.food && p.name == i.portion
                        );

                        if (portionData) {
                          sumWithDefaultWeight +=
                            parseFloat(portionData?.weight) *
                            parseFloat(i.quantity);
                        } else {
                          sumWithDefaultWeight += parseFloat(
                            i.quantity * i.defaultWeight
                          );
                        }
                      }
                    }
                  });

                  if (ingredients[0]?.defaultMeasurement) {
                    sumWithDefaultWeight = Math.ceil(
                      sumWithDefaultWeight / ingredients[0].defaultWeight
                    );
                    portion = ingredients[0].defaultMeasurement;
                  }
                } else {
                  sumWithDefaultWeight = 0;
                  portion = 'gram'

                  // THIS FOR 1 INGREDIENT WITH NO DEFAULT MEASUREMENT AND PORTION IS GRAM
                  if (ingredients[0].portion === 'gram' && !ingredients[0]?.defaultMeasurement) {
                    sum = sum > 100 ? this.roundUpToNearest100(sum) : sum;
                  }

                  if(ingredients[0].portion !== 'gram' && !ingredients[0]?.defaultMeasurement) {
                    let portionData

                    portionData = this.portions.find(
                      (p) => p.food.toLowerCase() == ingredients[0].food.toLowerCase() && p.name.toLowerCase() == ingredients[0]?.portion?.toLowerCase());

                      sum = Math.ceil(ingredients[0].quantity * portionData?.weight)
                      sum = sum > 100 ? this.roundUpToNearest100(sum) : sum
                  }

                  // THIS FOR 1 INGREDIENT WITH DEFAULT MEASUREMENT
                  if (ingredients[0]?.defaultMeasurement) {
                    let portionData

                    portionData = this.portions.find(
                      (p) => p.food.toLowerCase() == ingredients[0].food.toLowerCase() && p.name.toLowerCase() == ingredients[0]?.portion?.toLowerCase());



                    // INGREDIENT PORTION DOESN"T MATCH WITH THE DEFAULT PORTION
                    if(!portionData) {
                      portionData = this.portions.find(
                        (p) => p.food.toLowerCase() == ingredients[0].food.toLowerCase() && p.name.toLowerCase() == ingredients[0].defaultMeasurement.toLowerCase());
                    }

                    // let portionData2 = this.portions.filter(
                    //   (p) => p.food.toLowerCase() == ingredients[0].food.toLowerCase());

                    portion = ingredients[0].defaultMeasurement;


                    if(ingredients[0].portion != 'gram')
                      sumWithDefaultWeight = (ingredients[0].quantity * parseFloat(portionData?.weight)) 
                    else
                      sumWithDefaultWeight = ingredients[0].quantity

                    sumWithDefaultWeight = Math.ceil(sumWithDefaultWeight / parseFloat(ingredients[0].defaultWeight))

                  }
                }

                return !sumWithDefaultWeight
                  ? {
                      portion,
                      food: ing.food,
                      id: ing.id,
                      quantity:
                        sum < 1
                          ? sum.toFixed(2)
                          : this.$options.filters.numberFormat(sum.toFixed(2)),
                    }
                  : {
                      portion,
                      food: ing.food,
                      id: ing.id,
                      quantity:
                        sumWithDefaultWeight < 1
                          ? sumWithDefaultWeight.toFixed(2)
                          : this.$options.filters.numberFormat(
                              sumWithDefaultWeight.toFixed(2)
                            ),
                    };
              });

              uniqCollection = orderBy(uniqCollection, 'food', 'asc');

              uniqCollection.forEach((i) => {
                data.push({
                  id: i.id,
                  ing: [i.food, i.quantity, i.portion],
                });
              });

              this.tableData.push({
                headers: header,
                content: data,
                id: 'categorized' + i,
              });
            });
          }

          // NO CATEGORIES
          if (
            ingredientsCollection.filter(
              (i) => typeof i.category == 'undefined'
            )
          ) {
            let data = [];

            var categorizedIngredients = ingredientsCollection.filter((i) => {
              return typeof i.category == 'undefined';
            });

            let uniqCollection = uniqBy(
              categorizedIngredients,
              function (meal) {
                return `${meal.food}_${meal.portion}`;
              }
            );

            uniqCollection = uniqCollection.map((ing) => {
              let sum = parseFloat(ing.quantity);
              let portion = ing.portion;
              let ingredients = categorizedIngredients.filter(
                (i) => i.food == ing.food
              );

              if (ingredients.length > 1) {
                // sum = 0
                portion = 'gram';
                ingredients.forEach((i) => {
                  if (i.portion == 'gram') {
                    sum += parseFloat(i.quantity);
                  } else {
                    let portionData = this.portions.find(
                      (p) => p.food == i.food && p.name == i.portion
                    );
                    if (portionData) {
                      sum +=
                        parseFloat(portionData.weight) * parseFloat(i.quantity);
                    }
                  }
                });

                if (ingredients[0]?.defaultMeasurement) {
                  sum = Math.ceil(sum / ingredients[0].defaultWeight);
                  portion = ingredients[0].defaultMeasurement;
                }
              }

              if (portion === 'gram' && !ingredients[0]?.defaultMeasurement) {
                sum = sum > 100 ? this.roundUpToNearest100(sum) : sum;

                if (sum >= 1000) portion = 'kg';
              }

              return {
                portion,
                food: ing.food,
                quantity:
                  sum < 1
                    ? sum.toFixed(2)
                    : this.$options.filters.numberFormat(sum.toFixed(2)),
              };
            });

            uniqCollection = orderBy(uniqCollection, 'food', 'asc');

            uniqCollection.forEach((i) => {
              data.push({
                id: i.id,
                ing: [i.food, i.quantity, i.portion],
              });
            });

            if (data.length) {
              this.tableData.push({
                headers: header,
                content: data,
                id: 'uncategorized',
              });
            }
          }

          resolve('Successful');
        }, 1000);
      });
    },
    loadIngs() {
      this.loading = true;

      Promise.resolve(this.displayIngs()).then(() => {
        this.loading = false;
      });
    },
    dlShoppingList() {
      if (this.user.role != 'member') {
        if (!this.user.customLogo || !this.user.brandColor)
          this.$emit('openWarningDialog');
        else {
          console.log('download');
          this.downloadingShoppingList = true;
          Promise.resolve(this.downloadShoppingList()).then(() => {
            this.downloadingShoppingList = false;
          });
        }
      } else {
        this.downloadingShoppingList = true;
        Promise.resolve(this.downloadShoppingList()).then(() => {
          this.downloadingShoppingList = false;
        });
      }
    },
    roundUpToNearest100(num) {
      return Math.ceil(num / 100) * 100;
    },

    getLink(id) {
      const found = this.ings.find((i) => i.id == id);

      return found?.link ? found?.link : '-';
    },
    buyProduct(link) {
      setTimeout(() => {
        window.open(link, '_blank')
      })
    }
  },
};
</script>
